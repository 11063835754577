import { Box, Text } from '@chakra-ui/react';
import { useAuth } from 'Context/authContext';
import React, { useEffect, useState } from 'react';

const Quote = () => {
  const controller = new AbortController();
  const signal = controller.signal;
  const [randomQuote, setRandomQuote] = useState();
  const { authUser } = useAuth();
  const motivationCount =
    authUser?.motivationCount ?? Math.floor(Math.random() * 1461) + 1;
  useEffect(() => {
    fetch('https://type.fit/api/quotes', { signal })
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        setRandomQuote(data[motivationCount]);
      });
    return () => {
      controller.abort();
    };
  }, []);

  return (
    <>
      <Box
        padding={10}
        display={{ sm: 'block', md: 'flex' }}
        alignItems={'baseline'}
        justifyContent={'flex-start'}
        maxWidth={'800px'}
      >
        <Text
          display={'flex'}
          justifyContent={'center'}
          fontWeight={'bold'}
          fontStyle={'italic'}
          fontSize={25}
          alignItems={'center'}
        >
          <Box borderLeft={'5px solid gray'} height={65} mr={5}></Box>
          {randomQuote?.text}
        </Text>
        <Text
          display={'flex'}
          justifyContent={'flex-end'}
          fontWeight={'bold'}
          mt={{ sm: 2, md: -5 }}
        >
          {randomQuote?.author}
        </Text>
      </Box>
    </>
  );
};

export default Quote;
