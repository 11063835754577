// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getStorage, ref as FBStorageRef } from 'firebase/storage';
import { getDatabase, ref as FireBaseDBRef } from 'firebase/database';

// import { getAnalytics } from 'firebase/analytics';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
  apiKey: process.env.REACT_APP_apiKey,
  authDomain: process.env.REACT_APP_authDomain,
  databaseURL: process.env.REACT_APP_databaseURL,
  projectId: process.env.REACT_APP_projectId,
  storageBucket: process.env.REACT_APP_storageBucket,
  messagingSenderId: process.env.REACT_APP_messagingSenderId,
  appId: process.env.REACT_APP_appId,
  measurementId: process.env.REACT_APP_measurementId,
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(firebaseInitialize);

// creates different instances of firebase utilities
export const auth = getAuth(app);
export const db = getDatabase(app);
export const storage = getStorage(app);
export const dbRef = FireBaseDBRef(db); // for `get(child())` methodss

export const getStorageRef = (storageRefPath) => {
  return FBStorageRef(storage, storageRefPath);
};
