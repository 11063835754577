import React from 'react';
import "./PageNotFound.scss"

const PageNotFound = () => {
  return (
    <div className="pageNotFound">
      <div className="fof">
        <h1>Error 404</h1>
      </div>
    </div>
  );
};

export default PageNotFound;
