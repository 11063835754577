// @ts-nocheck comment
import {
  Box,
  Flex,
  Select,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import CustomButton from 'components/Shared/FormControls/CustomButton';
import { getAttendance, GetBusinessDays, YearHelper } from 'helper/lib';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { UserPayslilpSchema } from 'Schema';
import { UserPayslipInputs } from 'Types';
import Loader from 'components/Shared/Loader';
import { Months } from 'helper/constants';
import NoData from 'components/NoData';

const UserLeaves = () => {
  const [yearList, setYearList] = useState([]);
  const [generateLoading, setGenerateLoading] = useState(false);
  const [showResult, setShowResult] = useState(false);
  const [attendanceData, setAttendanceData] = useState([]);
  const [remainingLeaves, setRemainingLeaves] = useState(0);

  const headerTitles = ['Months', 'Total working days', 'Present', 'Absent'];

  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm<UserPayslipInputs>({
    resolver: yupResolver(UserPayslilpSchema),
  });
  const watchAllFields = watch(); // react hook form watch all fields

  const onSubmit = async (e: any) => {
    setGenerateLoading(true);

    let remainingLeave = 24;
    const attendance = await getAttendance({ specificYear: e.year });
    // setAttendanceData()
    const temp = Object.entries(attendance.data)?.map((item, index) => {
      return {
        month: item[0],
        totalWorkingDays: GetBusinessDays(Object.keys(item[1])[0]),
        presentDays: Object.values(item[1])?.map((val) => val?.checkedIn)
          ?.length,
        absentDays: Object.values(item[1]).filter((val) => val === 'Absent')
          .length,
        remainingLeaves:
          remainingLeave -
          Number(
            Object.values(item[1]).filter((val) => val === 'Absent').length,
          ),
      };
    });

    const remainingLeavesTemp = Object.entries(attendance.data)?.map((item) => {
      setRemainingLeaves(
        24 -
          Number(
            Object.values(item[1]).filter((val) => val === 'Absent').length,
          ),
      );
      return {
        month: item[0],
        totalWorkingDays: GetBusinessDays(Object.keys(item[1])[0]),
        presentDays: Object.values(item[1])?.map((val) => val?.checkedIn)
          ?.length,
        absentDays: Object.values(item[1]).filter((val) => val === 'Absent')
          .length,
      };
    });
    setAttendanceData(temp);
    setGenerateLoading(false);
    setShowResult(true);
  };

  useEffect(() => {
    YearHelper(setYearList);
    const month = new Date().getMonth() >= 12 ? 1 : new Date().getMonth() + 1;
    reset({
      month,
      year: new Date().getFullYear(),
    });
  }, []);

  useEffect(() => {
    setShowResult(false);
  }, [watchAllFields.year]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <TableContainer sx={{ padding: 0, borderRadius: '6px' }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            marginBottom: 6,
            flexWrap: 'wrap',
          }}
        >
          <Box mr={2}>
            <Select
              placeholder={watchAllFields.year ? '' : 'Year'}
              maxWidth={120}
              marginRight={5}
              marginBottom={3}
              {...register('year')}
            >
              <option disabled hidden>
                Year
              </option>
              {yearList.map((item) => (
                <option value={item} key={item}>
                  {item}
                </option>
              ))}
            </Select>
            <Text color={'red'}>{errors.year?.message}</Text>
          </Box>
          <Box marginRight={6}>
            <CustomButton
              type="submit"
              text="Generate"
              isLoading={generateLoading}
              disabled={generateLoading}
              buttonStyleProps={{ maxWidth: 200, marginRight: 8 }}
            />
          </Box>
        </Box>
        {generateLoading ? (
          <Flex justifyContent={'center'} alignItems={'center'} color={'gray'}>
            <Loader />
          </Flex>
        ) : showResult && attendanceData?.length ? (
          <>
            <Box>
              <Text
                display={'flex'}
                justifyContent={'flex-end'}
                fontWeight={'bold'}
              >
                Remaining Leaves: {remainingLeaves}
              </Text>
            </Box>
            <Box width={'100%'} overflow={'auto'}>
              <Table variant="simple" colorScheme="cyan">
                <Thead backgroundColor={'blackAlpha.800'}>
                  <Tr>
                    {headerTitles?.map((headerTitle, index) => (
                      <Th key={headerTitle} color={'white'}>
                        {headerTitle}
                      </Th>
                    ))}
                  </Tr>
                </Thead>
                <Tbody>
                  {attendanceData?.map((item, index) => {
                    return (
                      <Tr key={item.month + 1}>
                        <Td>{Months[Number(item.month) + 1]}</Td>
                        <Td>{item?.totalWorkingDays}</Td>
                        <Td>{item?.presentDays}</Td>
                        <Td>{item?.absentDays}</Td>
                      </Tr>
                    );
                  })}
                </Tbody>
              </Table>
            </Box>
          </>
        ) : showResult && !attendanceData?.length ? (
          <NoData text={'No record found.'} />
        ) : (
          <NoData
            text={'Select above option and click Generate to view the report.'}
          />
        )}
      </TableContainer>
    </form>
  );
};

export default UserLeaves;
